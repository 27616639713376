<template>
  <div>
    <div class="nav-box">
      <div class="lf-box">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div class="rt-box">
        <ul>
          <li @click="homePage">首页</li>
          <li @click="skip">采购公告</li>
          <li @click="enquiry">询价公告</li>
<!--          <li @click="skip">供应商公告</li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navBox',
  data () {
    return {}
  },
  methods: {
    skip () {
      this.$router.push('/notice')
    },
    enquiry () {
      this.$router.push('/enquiry')
    },
    homePage () {
      this.$router.push('/index')
    }
  }
}
</script>

<style lang="less">
.nav-box {
  width: 1200px;
  margin: 0 auto;
  height: 80px;
  line-height: 80px;

  .lf-box {
    margin-top: 12px;
    float: left;
  }

  .rt-box {
    float: right;

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: 0 20px;
        list-style: none;
        float: left;

        &:hover {
          cursor: pointer;
          color: #3150e9;
          border-bottom: 2px solid #3150e9;
        }
        &:focus {
          color: #3150e9;
          border-bottom: 1px solid #3150e9;
        }
      }
    }
  }
}
</style>
